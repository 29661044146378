import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>#Rita1tíma</p>
        <a
          className="App-link"
          href="https://blockly.games/?lang=fo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blokkaspølini
        </a>
        <p>Stýr teldunum heldur enn at lata teldurnar stýra tær.</p>
      </header>
    </div>
  );
}

export default App;
